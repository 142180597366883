import { Link } from "react-router-dom";
import img1 from "../assets/images/products/product1.jpg";
import img2 from "../assets/images/products/product2.jpg";
import img3 from "../assets/images/products/product3.jpg";
import img4 from "../assets/images/products/product4.jpg";

const TopProducts = () => {
    return (
        <>
            <section className="top-product-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-herader">
                                <h3 className="text-aling-start">OUR TOP PRODUCTS</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="top-product-card">
                                <div className="top-product-image">
                                    <img src={img1} alt="products" />
                                </div>
                                <div className="top-product-details">
                                    <h3>Crypto Currency</h3>
                                    <Link to="/#">Buy Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="top-product-card">
                                <div className="top-product-image">
                                    <img src={img2} alt="products" />
                                </div>
                                <div className="top-product-details">
                                    <h3>Crypto Currency</h3>
                                    <Link to="/#">Buy Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="top-product-card">
                                <div className="top-product-image">
                                    <img src={img3} alt="products" />
                                </div>
                                <div className="top-product-details">
                                    <h3>Crypto Currency</h3>
                                    <Link to="/#">Buy Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="top-product-card">
                                <div className="top-product-image">
                                    <img src={img4} alt="products" />
                                </div>
                                <div className="top-product-details">
                                    <h3>Crypto Currency</h3>
                                    <Link to="/#">Buy Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TopProducts;