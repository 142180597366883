import { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

const Counter = () => {
    const [counterOn, setCounterOn] = useState(false);
    return (
        <>
            <section className="counter-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="counter-info">
                                <p>Boasting over 180 Million accounts and 6.1 billion transactions, THUMBTACK is the workd's fastest-growing public chain</p>
                            </div>
                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                <div className="counter-parent">
                                    <div className="counter-childs">
                                        <h3>{counterOn && <CountUp start={0} end={175632339} duration={2} />}</h3>
                                        <h1>Total Number of Accounts</h1>
                                    </div>
                                    <div className="counter-childs">
                                        <h3>{counterOn && <CountUp start={0} end={6185525248} duration={2} />}</h3>
                                        <h1>Transaction Count</h1>
                                    </div>
                                    <div className="counter-childs">
                                        <h3>{counterOn && <CountUp start={0} end={53382086} duration={2} />}</h3>
                                        <h1>Block Height</h1>
                                    </div>
                                    <div className="counter-childs">
                                        <h3>$ {counterOn && <CountUp start={0} end={8455} duration={3} />} B</h3>
                                        <h1>Transfer Amount of Core Tokens</h1>
                                    </div>
                                </div>
                            </ScrollTrigger>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Counter;