// // import bannerFeaturedImg from "../assets/images/banner/banner_img1.jpg";
// // import bannerFeaturedImg2 from "../assets/images/banner/banner_img2.jpg";
// // import bannerFeaturedImg3 from "../assets/images/banner/banner_img3.jpg";
// // import Slider from "../components/slider/Slider";

// const Banner = () => {
// //     const imageData = [
// //         {
// //             image: bannerFeaturedImg
// //         },
// //         {
// //             image: bannerFeaturedImg2
// //         },
// //         {
// //             image: bannerFeaturedImg3
// //         },
//     // ]
//     return (
//         <>
// {/* //             <section className="banner-section">
// //                 <div className="container">
// //                     <div className="row">
// //                         <div className="col-md-12 noMarginPadding">
// //                             <div className="slider-wrapper">
// //                                 <Slider data={imageData} autoSlide={true} slidingTime={6000} />
// //                             </div>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </section> */}
//         </>
//     )
// }

// export default Banner;

import bannerVideo from "../assets/images/video/banner_video.mp4";

const Banner = () => {
    return (
        <>
            <section className="banner-section">
                <div className="banner-video-wrapper">
                    <video loop autoPlay muted>
                        <source src={bannerVideo} type="video/mp4" />
                    </video>
                    <div className="banner-overlay"></div>
                    <div className="banner-details">
                        <h1>The World's Leading</h1>
                        <h2>Cryptocurrency Platform</h2>
                        <div className="banner-details-more">
                            <div className="banner-details-inner">
                                <p><i className="fa fa-check-circle"></i> Trusted by more than <span>20M users</span> world-wide</p>
                                <p><i className="fa fa-check-circle"></i> Leader in <span>regulatory compliance</span> and <span>security certifications</span></p>
                                <p><i className="fa fa-check-circle"></i> The industry’s most comprehensive <span>insurance coverage</span> and <span>verified proof of reserves</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner;