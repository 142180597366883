import ScrollTrigger from 'react-scroll-trigger';
import mobile_presentation_trading from "../assets/images/phone_view/phone_view_trading.png";
import mobile_presentation_profile from "../assets/images/phone_view/phone_view_profile.png";
import { useState } from 'react';

const Presentation = () => {
    const [counterOn, setCounterOn] = useState(false);
    return (
        <>
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>

                <div className="presentation-wrapper">
                    <div className="bg-glow"></div>
                    <div className="presentation-parent">
                        <div className="presentation-childs">
                            <img className={counterOn && "animate-mob1"} src={mobile_presentation_trading} alt="presentation" />
                        </div>
                        <div className="presentation-childs">
                            <img className={counterOn && "animate-mob2"}src={mobile_presentation_profile} alt="presentation" />
                        </div>
                    </div>
                </div>
            </ScrollTrigger>
        </>
    )
}

export default Presentation;