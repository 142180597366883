import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Navigation from "./layouts/Navigation";
import Home from "./pages/Home";
import Footer from "./layouts/Footer";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import FlashScreen from "./pages/FlashScreen";

const App = () => {

  const [isAuthPage, sertIsAuthPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/signup") {
      return sertIsAuthPage(false);
    } else {
      return sertIsAuthPage(true);
    }
  }, [location.pathname]);
  return (
    <>
      {isAuthPage ? <Navigation /> : <></>}
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/login" element={<Login />} exact />
        <Route path="/signup" element={<SignUp />} exact />
      </Routes>
      <FlashScreen />
      {isAuthPage ? <Footer /> : <></>}
    </>
  )
}

export default App;