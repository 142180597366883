import { Link } from "react-router-dom";
import authImg from "../../assets/images/about/about_featured_image.jpg";

const Login = () => {

    return (
        <>
            <section className="auth-section">
                <div className="auth-wrapper">
                    <div className="auth-parent">
                        <div className="auth-childs">
                            <div className="auth-info">
                                <form>
                                    <div className="auth-heading">
                                        <h3>LOGIN</h3>
                                    </div>
                                    <div className="auth-subtitle">
                                        <h4>Welcome Back</h4>
                                        <p>Thank you for getting back, please login to your account by filling these form</p>
                                    </div>
                                    <div className="global-form">
                                        <div className="form-groups">
                                            <label>Enter Email Address</label>
                                            <input type="text" placeholder="example@gmail.com" />
                                        </div>
                                        <div className="form-groups">
                                            <label>Enter Password</label>
                                            <input type="password" placeholder="*********" />
                                        </div>
                                    </div>
                                    <div className="auth-operation-btns">
                                        <div className="forgot-pass">
                                            <Link to="/#">Forgot Password?</Link>
                                        </div>
                                    </div>
                                    <div className="form-submit-btn">
                                        <button>LOGIN</button>
                                        <button>SIGN UP</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="auth-childs login-child">
                            <img src={authImg} alt="auth" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login;