import { Link } from "react-router-dom";
import { useState } from "react";
import logo_white from "../assets/images/logo/logo_main.png";
import logo_white_ from "../assets/images/logo/logo_main_white.png";

const Navigation = () => {
    const [isNav, setIsNav] = useState(false);

    return (
        <>
            <section className="navigation-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="navigation-parent">
                                <div className="navigation-child">
                                    <div className="navigation-logo">
                                        <img src={logo_white} className="logo_main" alt="logo" />
                                        <img src={logo_white_} className="logo_responsive" alt="logo" />
                                    </div>
                                </div>
                                <div className="navigation-child">
                                    <div className={isNav ? "menu manageNav" : "menu"}>
                                        <div className="close-nav">
                                            <i className="fa fa-times" onClick={()=> setIsNav(!isNav)}></i>
                                        </div>
                                        <ul>
                                            <li>
                                                <Link to="/#">Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/#">About Us</Link>
                                            </li>
                                            <li>
                                                <Link to="/#">Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/#">Customer Care</Link>
                                            </li>
                                            <li>
                                                <Link to="/#">Career</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="navigation-child">
                                    <div className="navigation-action-btns">
                                        <Link to="/login" className="ntn_login">
                                            <li>LOGIN</li>
                                        </Link>
                                        <Link to="/signup" className="ntn_register">
                                            <li>SIGN UP <i className="fas fa-sign-in-alt"></i></li>
                                        </Link>
                                        <div className="responsive-toggle-btn">
                                            <i className="fa fa-bars" onClick={() => setIsNav(!isNav)}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Navigation;