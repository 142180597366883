import { Link } from "react-router-dom";

const Services = () => {
    return (
        <>
            <section className="services-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-herader">
                                <h3>WHAT WE DO | OUR SERVICES</h3>
                                <p>We are the leading crypto treader and supplier </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="services-card">
                                <i className="fab fa-bitcoin"></i>
                                <h3>Crypto Exchange</h3>
                                <Link to="/#">Read More</Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="services-card">
                                <i className="fab fa-product-hunt"></i>
                                <h3>Product Sell</h3>
                                <Link to="/#">Read More</Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="services-card">
                                <i className="fas fa-exchange-alt"></i>
                                <h3>Currency Transfer</h3>
                                <Link to="/#">Read More</Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="services-card">
                                <i className="fa fa-cogs"></i>
                                <h3>Corporate Services</h3>
                                <Link to="/#">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Services;