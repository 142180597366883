import { Link } from "react-router-dom";

const SignUp = () => {
    return(
        <>
             <section className="auth-section">
                <div className="auth-wrapper">
                    <div className="auth-parent">
                        <div className="auth-childs">
                            <div className="auth-info">
                                <form>
                                    <div className="auth-heading">
                                        <h3>SIGN UP</h3>
                                    </div>
                                    <div className="auth-subtitle">
                                        <h4>Register Your Account</h4>
                                        <p>Please fill the required fields</p>
                                    </div>
                                    <div className="global-form">
                                        <div className="form-groups">
                                            <label>Enter Email Address</label>
                                            <input type="text" placeholder="example@gmail.com" />
                                        </div>
                                        <div className="form-groups">
                                            <label>Enter Password</label>
                                            <input type="password" placeholder="*********" />
                                        </div>
                                        <div className="form-groups">
                                            <label>Confirm Password</label>
                                            <input type="password" placeholder="*********" />
                                        </div>
                                    </div>
                                    <div className="auth-operation-btns">
                                        <div className="forgot-pass">
                                            <Link to="/#">Already have account?</Link>
                                        </div>
                                    </div>
                                    <div className="form-submit-btn">
                                        <button>REGISTER NOW</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SignUp;