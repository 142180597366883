import About from "./About";
import Banner from "./Banner";
import Counter from "./Counter";
import Presentation from "./Presentation";
import Services from "./Services";
import TopProducts from "./TopProducts";
import {Helmet} from "react-helmet";

const Home = () => {
    return (
        <>
            <Helmet><title>Thumbtrack - Welcome</title></Helmet>
            <Banner />
            <Presentation />
            <Services />
            <About />
            <Counter />
            <TopProducts />
        </>
    )
}

export default Home;