const FlashScreen = () => {
    return (
        <>
            <div className="flash_screen-wrapper">
                <div className="flashscreen-left-screen"></div>
                <div className="flashscreen-right-screen"></div>
            </div>
        </>
    )
}

export default FlashScreen;